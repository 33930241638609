import React from 'react'
import './stay.css'
import video from '../../Assets/video.mp4'
import img1 from '../../Assets/pres/4.jpg'
import img2 from '../../Assets/pres/1.jpg'
import img3 from '../../Assets/pres/2.jpg'
import img4 from '../../Assets/pres/3.jpg'
import { Carousel } from 'react-responsive-carousel';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Stay = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  return (
    <section className='stay'>
        <video src={video} preload autoPlay playsInline poster muted loop></video>
        <div className="hero flex">
            <div className="heroTitle">
                <span className='welc'>
                    Welcome to Hookup Lounge International.
                </span>
                <span className='welcTitle'>
                A place to rest, unwind, dine and explore, a stay at the Hookup Lounge International isn’t just another event in your calendar, it’s an experience you’ll cherish and speak of long after you leave.
                </span>
            </div>
            <div className="heroDesc">
                <span className="text1">
                At Hookup Lounge International, our 65 carefully curated bedrooms reflect elegance, charm, and the allure of upscale living. From intimate spaces offering a cozy atmosphere to grand suites delivering unparalleled luxury, each room invites you to indulge in magnificence.
                </span>
                <span className="text2">
                Elevate your stay with personalized concierge services and chauffeur-driven transportation, designed to cater to your every need. Every detail at Hookup Lounge International is crafted with your comfort and satisfaction in mind.
                </span>
            </div>
        </div>
        <div className="mainImg">
            <img src={img1} alt="" />
        </div>
        {isSmallScreen ? (
        <Carousel showThumbs={false}>
          <div>
            <img src={img2} alt="" />
          </div>
          <div>
            <img src={img3} alt="" />
          </div>
          <div>
            <img src={img4} alt="" />
          </div>
        </Carousel>
      ) : (
        <div className="secImg">
          <div className="secImg1">
            <img src={img2} alt="" />
          </div>
          <div className="secImg2">
            <img src={img3} alt="" />
            <img src={img4} alt="" />
          </div>
        </div>
      )}
        <div className="card grid">
            <h2>Rooms and Suites</h2>
            <span>Each of our rooms provides an up-to-date take on traditional elegance and refinement. From grand furniture to exceptional decor, realise the true meaning of escapism as you overlook the sweeping views of idyll Northumberland waiting beyond the window.</span>
            <Link to='/presidential-suites'>
              <button className="btn">Explore Presidential Suites</button>
            </Link>
            <Link to='/luxury-room'>
              <button className="btn">Explore Luxury Room</button>
            </Link>
            <Link to='/premium-room'>
              <button className="btn">Explore Premium Room</button>
            </Link>
            <Link to='/deluxe-room'>
              <button className="btn">Explore Deluxe Room</button>
            </Link>
        </div>
    </section>
  )
}

export default Stay