import React, { useRef } from 'react';
import './book.css';
import emailjs from '@emailjs/browser';
import { useHistory } from 'react-router-dom';

export const Book = () => {
  const form = useRef();
  const history = useHistory();

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const paymentMethod = formData.get('payment');

    emailjs.sendForm('service_6ks3m9o', 'template_1hv7dpm', form.current, 'YkblRA0F87spz8lUD')
      .then((result) => {
          console.log('message sent');
          alert('Thank you for booking with us!'); // Display the alert

          // Redirect to payment.jsx if payment method is creditcard
          if (paymentMethod === 'creditcard') {
            history.push('/payment'); // Change '/payment' to the appropriate route
          }
      })
      .catch((error) => {
          console.error('Email send failed:', error);
          alert('Sorry, there was an error. Please try again later.');
      });
  };

  return (
    <section id='book' className='book'>
      <div className="book-container">
        <div className="bookTitle">
          <h2>Booking Form</h2>
        </div>
        <form ref={form} className='' onSubmit={sendEmail}>
          <label>Your Full Name*</label>
          <input required className='input' type="text" placeholder='ex. John Doe' name="name1" />
          <label>Partner's Full Name</label>
          <input className='input' placeholder='ex. Jane Doe' type="text" name="name2" />
          <label>Phone Number*</label>
          <input required className='input' placeholder='ex. +15086223556' type="number" name="num" />
          <label>Email Address*</label>
          <input required className='input' type="email" placeholder='email@email.com' name="email" />
          <label>City Address*</label>
          <input required className='input' type="text" placeholder='City' name="city" />
          <input required className='input' type="text" placeholder='State / Province' name="state" />
          <label>Room Type*</label>
          <select required className='input' name="room">
              <option value="Deluxe">Deluxe Room</option>
              <option value="Premium">Premium Room</option>
              <option value="Luxury">Luxury Room</option>
              <option value="Presedential">Presedential Room</option>
          </select>
          <label>Number of Guest*</label>
          <input required className='input' placeholder='1' type="number" name="guest" />
          <label>Check-In Date*</label>
          <input required className='input' placeholder='' type="date" name="checkin" />
          <label>Check-Out Date*</label>
          <input required className='input' placeholder='' type="date" name="checkout" />
          <label>Payment Methods*</label>
          <select required className='input' name="payment">
              <option value="creditcard">Credit/Debit Card</option>
              <option value="western">Western Union</option>
              <option value="MoneyGram">MoneyGram</option>
              <option value="Worldremit">World Remit</option>
              <option value="ria">Ria Money Transfer</option>
              <option value="Remitly">Remitly</option>
              <option value="PayPal">PayPal</option>
              <option value="wise">Wise Money Transfer</option>
              <option value="Sendwave">Sendwave</option>
              <option value="Quikz">Quikz</option>
          </select>
          <label>Special Request</label>
          <textarea className='text' placeholder='ex. add extra blanket' name="message" id="" cols="10" rows="5"></textarea>
          <input className='btn' type="submit" value="Book Now!" />
        </form>
      </div>
    </section>
    

  );
};

export default Book